import React from 'react'
import Layout from "../components/layout"
import { graphql } from 'gatsby'
import Seo from '../components/seo'
import Subscribe from '../components/subscribe'
import PieChart from "../components/PieChart";
import { motion } from "framer-motion";
import { InView } from 'react-intersection-observer';
import { StaticImage } from 'gatsby-plugin-image';
// import { ChevronRightIcon } from '@heroicons/react/20/solid'

const pieChart = [
    { label: "Latina/Hispanic", color: "#E57200", value: 39 },
    { label: "Black", color: "#00859B", value: 23 },
    { label: "Asian", color: "#A73A64", value: 22 },
    { label: "White", color: "#F1BE48", value: 9 },
    { label: "Mutiracial", color: "#005670", value: 5 },
  ];

  const colors = pieChart.map((item) => item.color);
  const labels = pieChart.map((item) => item.label);
  const data = pieChart.map((item) => item.value);

const stats = [
  { id: 1, name: 'Scholarships Distributed', value: '$222,000' },
  { id: 2, name: 'WiNternships Provided', value: '80' },
  { id: 3, name: 'WiNterns who were first generation college students', value: '45%' },
  { id: 4, name: 'Faculty Hosts across UT Neuroscience and Dell Medical Neurology Departments', value: '25' },
]

const statVariants = {
  hidden: { y: 80, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1.25,
    },
  },
};

const Post = ({
  data: {
    contentfulPage: {
      title,
      bodyText,
    },
  },
}) => {
  return (
  <div>
    <Layout siteTitle={title}>
      <div className="relative bg-gradient-to-b from-white to-gray-50">
        <main className="lg:relative">
          <div className="mx-auto w-full max-w-7xl pt-16 pb-20 text-center lg:py-48 lg:text-left">
            <div className="px-4 sm:px-8 lg:w-1/2 xl:pr-16">
              

              {/* <div className="mt-0 sm:mt-0">
                <a href="/join-us/open-positions" className="inline-flex space-x-6">
                  <span className="rounded-full bg-indigo-600/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-600 ring-1 ring-inset ring-indigo-600/10">
                    We're hiring!
                  </span>
                  <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-600">
                    <span>Apply to be WiN's next Executive Director</span>
                    <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </a>
              </div> */}


              <h1 className="text-4xl sm:mt-3 mt-10 font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-5xl xl:text-6xl">
                <span className="block xl:inline">Less bias leads to</span>{' '}
                <span className="block text-indigo-600 xl:inline">better science</span>
              </h1>
              <p className="mx-auto mt-3 max-w-md text-gray-500 sm:text-3xl md:mt-5 md:max-w-3xl">
              An inclusive scientific community produces better outcomes
              </p>              
              <div className="mt-10 mb-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <a
                    href="/impact/our-impact"
                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 md:py-4 md:px-10 md:text-lg"
                  >
                    Learn More
                  </a>
                </div>
                <div className="group mt-3 rounded-md shadow sm:mt-0 sm:ml-3 relative">
                  <a
                    href="https://donate.neurowomen.org/donate"
                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-indigo-600 hover:bg-gray-50 md:py-4 md:px-10 md:text-lg z-10 relative"
                  >
                    Donate Today
                  </a>
                  <div className="opacity-0 lg:group-hover:opacity-100 transition-opacity duration-700 absolute top-0 left-0 mt-3 z-0">
                    <div className='mt-16 border-l-2 border-gray'>
                      <div className="ml-2 mr-auto max-w-md">
                        <p className="text-xs leading-4 font-semibold text-gray-600">We're proud to be recognized with the <span className='italic hover:underline text-indigo-900'><a href="https://www.guidestar.org/profile/shared/66bcefcb-4904-48d7-9e35-e597617825db" target="_blank" rel="noopener noreferrer">Guidestar Platinum Transparency</a></span> certification, reflecting our commitment to integrity and transparency.</p>
                      </div>
                      <a href="https://www.guidestar.org/profile/shared/66bcefcb-4904-48d7-9e35-e597617825db" target="_blank" rel="noopener noreferrer">
                        <StaticImage src="../images/platinum2023seal.svg" alt="Platinum Seal" className="mx-auto w-20 mt-3 ml-2 grayscale hover:grayscale-0" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="relative h-64 w-full md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:h-full lg:w-1/2">
            <StaticImage src="../images/homepage.jpg" alt="Platinum Seal" className="absolute inset-0 h-full w-full object-cover" />
          </div>
        </main>
      </div>

        

      <div className="overflow-hidden border-b-2 border-indigo-600 bg-gray-50 py-16">
      <div className="mx-auto max-w-7xl space-y-8 px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-prose text-base lg:max-w-none">
          <h2 className="text-lg font-semibold text-indigo-600">WiN's Mission</h2>
          <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
            Our Purpose
          </p>
        </div>
        <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-8">
          <div className="relative z-10">
            <div className="mx-auto mt-3 max-w-md text-gray-500 sm:text-3xl md:mt-5 md:max-w-3xl">
              <p>
              Women in Neuroscience is building a diverse community of students to pursue leadership careers and create representative diversity in the fields of neuroscience and neurology.<br/><br/>

              Through paid internships, educational opportunities, and one-on-one mentoring, WiN ensures its participants build the confidence and leadership skills necessary to realize their career goals.<br/><br/>

              Our programs are open to people of all genders.
              </p>
            </div>
            
          </div>
          <div className="relative mx-auto mt-12 max-w-prose text-base lg:mt-0 lg:max-w-none">
            <svg
              className="absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="bedc54bc-7371-44a2-a2bc-dc68d819ae60"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)" />
            </svg>
            <blockquote className="relative rounded-lg bg-white shadow-lg">
              <div className="rounded-t-lg px-6 py-8 sm:px-10 sm:pt-10 sm:pb-8">
                <div className="relative mt-8 text-lg font-medium text-gray-700">
                  <svg
                    className="absolute top-0 left-0 h-8 w-8 -translate-x-3 -translate-y-2 transform text-gray-200"
                    fill="currentColor"
                    viewBox="0 0 32 32"
                    aria-hidden="true"
                  >
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                  </svg>
                  <p className="relative">
                  Compelling data shows that diverse groups, including women and people of color, generate more creative ideas and improved scientific outcomes than homogeneous groups.
                  </p>
                </div>
              </div>
              <cite className="relative flex items-center rounded-b-lg bg-indigo-600 py-5 px-6 not-italic sm:mt-10 sm:items-start sm:py-5 sm:pl-12 sm:pr-10">
              <span className="relative flex-none rounded-full border-2 border-white sm:absolute sm:top-0 sm:-translate-y-1/2 sm:transform">
                  <StaticImage src="../images/JNRlogo.jpg" alt="JNR logo" className="h-12 w-12 rounded-full bg-indigo-300 sm:h-20 sm:w-20"/>
                </span>
                <span className="relative ml-4 font-semibold leading-6 text-indigo-700 sm:ml-24 sm:pl-1">
                  <span className="font-semibold text-white sm:inline">Machlovi, S., Pero, A., Ng, S., Zhong, M., & Cai, D. (2020).</span>{' '}
                  <span className="sm:inline"><a href="https://doi.org/10.1002/jnr.24570">Women in Neuroscience: Where are we in 2019? <i>Journal of Neuroscience Research</i>, 99(1), 9–12. </a></span>
                </span>
              </cite>
            </blockquote>
          </div>
        </div>
      </div>
    </div>


    <div className="relative bg-gradient-to-b from-indigo-300 to-white py-16">
      <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:gap-24 lg:px-8">
        <div className="relative sm:py-16 lg:py-0">
          <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
            <div className="absolute inset-y-0 right-1/2 w-full py-16 rounded-r-3xl bg-gradient-to-b from-indigo-700 to-indigo-300 lg:right-72" />
            <svg
              className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
              width={404}
              height={392}
              fill="none"
              viewBox="0 0 404 392"
            >
              <defs>
                <pattern
                  id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-indigo-600" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
            </svg>
          </div>
          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20">
            <div className="relative overflow-hidden rounded-2xl py-64 shadow-xl">
              <iframe
                className="absolute inset-0 h-full w-full object-cover"
                src="https://www.youtube.com/embed/wio0Ge_Aa0o"
                title="Get to know Women in Neuroscience"
              />
              <div className="relative px-8">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




    <div className="overflow-hidden border-b-2 border-indigo-600 bg-white">
      <div className="relative mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
        <div className="absolute top-0 bottom-0 left-3/4 hidden w-screen bg-gray-50 lg:block" />
        <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
          <div>
            <h2 className="text-lg font-semibold text-indigo-600">The WiNternship</h2>
            <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">A Life-changing Experience</h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:col-start-2 lg:row-start-1">
            <svg
              className="absolute top-0 right-0 -mt-20 -mr-20 hidden lg:block"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
            </svg>
            <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
              <figure>
                <div className="!aspect-w-12 !aspect-h-7 lg:!aspect-none">
                  <StaticImage
                    className="rounded-lg object-cover object-center shadow-lg"
                    src="../images/HomeLifeChangingExp.jpg"
                    alt="Intership recipient, Tieranni Brim"
                  />
                </div>
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="prose prose-indigo mx-auto mt-5 text-gray-500 lg:col-start-1 lg:row-start-1 lg:max-w-none">
              <section className="overflow-hidden bg-white">
                    <div className="relative mx-auto max-w-7xl px-4 pt-20 pb-12 sm:px-6 lg:px-8 lg:py-20">
                      <svg
                        className="absolute top-full left-0 translate-x-80 -translate-y-24 transform lg:hidden"
                        width={784}
                        height={404}
                        fill="none"
                        viewBox="0 0 784 404"
                        aria-hidden="true"
                      >
                        <defs>
                          <pattern
                            id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                          >
                            <rect x={0} y={0} width={4} height={4} className="text-gray-200 md:hidden" fill="currentColor" />
                          </pattern>
                        </defs>
                        <rect width={784} height={404} fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)" />
                      </svg>

                      <div className="relative lg:flex lg:items-center">

                        <div className="relative">
                          <svg
                            className="absolute top-0 left-0 h-36 w-36 -translate-x-8 -translate-y-24 transform text-indigo-200 opacity-50"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 144 144"
                            aria-hidden="true"
                          >
                            <path
                              strokeWidth={2}
                              d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                            />
                          </svg>
                          <blockquote className="[border-left-width:0] relative">
                            <div className="text-2xl font-medium leading-9 text-gray-900">
                              <p>
                              I had the privilege of being one of the first WiN interns. I realized that I wasn’t in the lab because they wanted an expert to revolutionize the research being done. I was in the lab because they wanted someone who could learn—and someone who wanted to learn—and they saw that in me. 
                              </p>
                            </div>
                            <footer className="mt-8">
                              <div className="flex">
                                <div className="ml-4 lg:ml-0">
                                  <div className="text-base font-medium text-gray-900">-2022 WiNtern</div>

                                </div>
                              </div>
                            </footer>
                          </blockquote>
                        </div>
                      </div>
                    </div>
                  </section>
               </div>
          </div>
        </div>
        <div className="flex gap-x-4 -mt-10 justify-center">

          <a href="/impact/impact-stories"
            className="flex rounded-md z-10 border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 md:py-4 md:px-10 md:text-lg"
          >
            More WiNtern Stories{' '}
            <span className="text-white" aria-hidden="true">
              &rarr;
            </span>
          </a>
        </div>
      </div>
    </div>  

    <div className="pt-16 pb-4 bg-gray-200">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <h2 className="text-lg font-semibold text-indigo-600">Impact</h2>
          <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">How our WiNterns Identify</h3>
          <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
          <div style={{ maxHeight: '550px' }} className="lg:w-full lg:max-w-2xl lg:justify-center lg:flex lg:flex-auto">
              <PieChart colors={colors} labels={labels} data={data} className="" />
          </div>
            <div className="lg:flex lg:flex-auto lg:justify-center">
              <dl className="w-64 space-y-8 xl:w-80">
              {stats.map((stat) => (
                <InView key={stat.id} threshold={0.5} triggerOnce={true}>
                {({ ref, inView }) => (
                  <motion.div
                    key={stat.id}
                    ref={ref}
                    variants={statVariants}
                    initial="hidden"
                    animate={inView ? 'visible' : 'hidden'}
                    className="flex flex-col-reverse gap-y-4"
                  >
                    <dt className="text-base leading-7 text-gray-600">{stat.name}</dt>
                    <motion.dd className="text-5xl font-semibold tracking-tight text-gray-900">
                      {stat.value}
                    </motion.dd>
                  </motion.div>
                )}
              </InView>
                ))}
              </dl>
            </div>
            
          </div>

            <div className="flex py-6 justify-center">
              <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <a
                    href="/impact/our-impact"
                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 md:py-4 md:px-10 md:text-lg"
                  >
                    Learn more about how we're changing neuroscience<span aria-hidden="true">&nbsp;→</span>
                  </a>
                </div>
                <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                  <a
                    href="https://donate.neurowomen.org/donate"
                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-indigo-600 hover:bg-gray-50 md:py-4 md:px-10 md:text-lg"
                  >
                    Donate Today
                  </a>
                </div>
              </div>
            </div>

        </div>
      </div>
    </div>

    <div className="bg-gradient-to-b from-gray-200 to-white">
    <Subscribe />
    </div>

    </Layout>
    </div>
  )
}

export const Head = ({ data }) => <Seo title={data.contentfulPage.title} />

export default Post

export const query = graphql`
  query MyQuery {
    contentfulPage(title: {eq: "Home"}, bodyText: {raw: {}}) {
      title
      bodyText {
        raw
      }
    }
  }
`