import React, { useRef, useEffect } from "react";

const PieChart = ({ colors, labels, data, className }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      new window.Chart(ctx, {
        type: "doughnut",
        data: {
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: colors,
              hoverBackgroundColor: colors,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              labels: {
                font: {
                  family: 'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                  size: 16,
                },
              },
            },
            tooltip: {
              bodyFont: {
                family: 'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                size: 16,
              },
              callbacks: {
                title: function () {
                  return ""; // Return an empty string to disable the default title
                },
                label: function (context) {
                  const label = context.label || "";
                  const value = context.parsed || 0;
                  return `${label}: ${value}%`;
                },
              },
            },
          },
        },
      });
    }
  }, [chartRef, colors, labels, data]);

  return <canvas className={className} ref={chartRef} />;
};

export default PieChart;
